<script setup>
import _debounce from 'lodash.debounce'

const props = defineProps(['columnWidth', 'columnKey', 'column', 'reverse', 'debounceMs', 'tableType'])
let columnWidth = props.columnWidth
const emitEvent = defineEmits(['column-resized', 'start-column-resize'])
let elements = []
let pageX = 0

const startColumnResize = (event) => {
  event.preventDefault()
  const colKeySelector = props.columnKey.includes('.') ? props.column.replaceAll('.', '\\.') : props.columnKey
  const selector = props.tableType?.length > 0 ? `.table-${props.tableType} .${colKeySelector}` : `.${colKeySelector}`
  elements = document.querySelectorAll(selector)
  if (!columnWidth || isNaN(columnWidth)) {
    columnWidth = elements[0].clientWidth
  }
  pageX = event.pageX
  document.addEventListener('mousemove', _debouncedCalcColumnWidth)
  document.addEventListener('mouseup', endColumnResize)
  emitEvent('start-column-resize')
}

const _calculateColumnWidth = function calculateColumnWidth (event) {
  if (!props.columnKey) {
    endColumnResize()
  } else {
    if (pageX !== 0 && columnWidth) {
      columnWidth = columnWidth + (!props.reverse ? (event.pageX - pageX) : -(event.pageX - pageX))
      columnWidth < 10 && (columnWidth = 10)
    }
    pageX = event.pageX
    setColumnWidth(elements, columnWidth + 'px')
  }
}

const _debouncedCalcColumnWidth = _debounce(_calculateColumnWidth, props.debounceMs || 5)

const setColumnWidth = (elements, width) => {
  elements.forEach(item => { item.style.width = width })
}
const endColumnResize = (event) => {
  document.removeEventListener('mousemove', _debouncedCalcColumnWidth)
  document.removeEventListener('mouseup', endColumnResize)
  pageX = 0
  emitEvent('column-resized', { column: props.column, columnWidth })
}
const classes = [
  'cursor-resize',
  'column-resize-handle'
]

</script>

<template>
  <div :class="classes" @mousedown="startColumnResize" @click="(e) => e.stopPropagation()">
    <slot></slot>
  </div>
</template>
